import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

import styles from "./productsList.module.scss"

import TitleBannerV2 from "../titleBannerV2/titleBannerV2"
import LocationPrice from "./locationPrice/locationPrice"

function ProductList(props) {

    const [productOptionColors, setProductOptionColors] = useState(null)

    const optionsColors = props?.product.options.find(el => {
        return el.name === "Color"
    })
    const optionsColorsLength = optionsColors?.values.length
    const optionsColorsValues = optionsColors?.values
    // debug
    // console.log("optionsColors:", optionsColors)
    console.log(props?.product)

    return (
        <Link to={"/shop/" + props?.product.handle}>
            <div className={styles.ProductList}>

                <div className={styles.image}>
                    <img src={props?.product.images[0].src} />
                    <img className={styles.hoverImage} src={props?.product.images[1].src} />
                </div>
                <div className={styles.info}>
                    <div className={styles.variants}>
                        <ul class="flex center middle">
                            {optionsColorsValues?.map((optionsColorsValue, i) => (
                                <li className={"bgv_" + optionsColorsValue.value.toLowerCase()} key={i}>
                                    <div>
                                        <span></span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={styles.title}><b>{props?.product.title}</b></div>
                    <div className={styles.price}>
                        <LocationPrice product={props?.product} currency={props?.currency} />
                    </div>
                </div>

            </div>
        </Link>
    )

} export default ProductList