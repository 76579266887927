import React, { useState, useEffect, useRef } from "react"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./productsCategorySlider.module.scss"

// Shop
import Client from "shopify-buy"
import {
  getCountryByAbbreviation,
  getCountry,
  formatLocaleCurrency,
} from "country-currency-map"

import ProductList from "../../product/productList"

import spin from "../../../images/spin.gif"

// Swiper import
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

const shopifyCollectionId = '272304406695'
const base64CollectionId = Buffer.from('gid://shopify/Collection/' + shopifyCollectionId).toString('base64')
const collectionId = base64CollectionId;

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  //domain: "jiwonchoi.myshopify.com/",
  domain: "shop.jiwchoi.com/",
  storefrontAccessToken: "1606be1610de6661c814f512b4bcdb71",
})

function ProductsCategorySlider() {

  const [products, setProducts] = useState(null)
  const [currencyLocation, serCurrencyLocation] = useState(null)

  // Get Products
  useEffect(() => {
    async function fetchProducts() {
      //const products = await client.product.fetch(PRODUCT_ID)
      //setproductItems(product)

      // Set a parameter for first x products, defaults to 20 if you don't provide a param
      client.collection.fetchWithProducts(collectionId, { productsFirst: 30 }).then((collection) => {
        // Do something with the collection
        console.log(collection);
        console.log(collection.products);
        setProducts(collection.products)
      });
    }

    fetchProducts()
  }, [])

  useEffect(() => {
    async function fetchCurrency() {
      const ipInfoResponse = await fetch(
        "https://ipinfo.io/json?token=01fdab52f173f3"
      )
      const ipJson = await ipInfoResponse.json()

      const ipCountry = ipJson ? ipJson.country : "US"
      // Manual set
      //const ipCountry = "US"

      const country = getCountryByAbbreviation(ipCountry)
      const { currency } = getCountry(country)

      serCurrencyLocation(currency)
    } fetchCurrency()
  }, [])

  console.log('products:', products);

  const params = {
    containerModifierClass: "productsCategorySlider",
    slidesPerView: 1,
    spaceBetween: 10,
    // grabCursor: true,
    // mousewheel: {
    //   invert: true,
    //   forceToAxis: true, // Set to true to force mousewheel swipes to axis.
    // },
    //parallax: true,
    navigation: {
      nextEl: ".swiper-btn-next",
    },
    breakpoints: {
      // when window width is <= 320px
      480: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      // when window width is <= 480px
      640: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      // when window width is <= 640px
      1024: {
        slidesPerView: 6,
        spaceBetween: 10,
      }
    }
  }

  return (
    <>
      <div className={'marginSection'}>

        {!products && (
          <img className={styles.spin} src={spin} />
        )}

        {/* {products && (
          <Swiper {...params}>

            {products?.map((product, i) => (
              <div key={i}>
                <ProductList product={product} currency={currencyLocation}></ProductList>
              </div>
            ))}

          </Swiper>
        )} */}

        {/* <Swiper {...params}>

          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
          <div>5</div>
          <div>6</div>
          <div>7</div>
          <div>8</div>

        </Swiper> */}
        <div className={'flex center'}>
          {products?.map((product, i) => (
            <div className={'col col-x6 col-l-x4 col-m-x3 col-s-x2'} key={i}>
              <ProductList product={product} currency={currencyLocation}></ProductList>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
export default ProductsCategorySlider
