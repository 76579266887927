import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderClose from "../../components/headerClose/headerClose"
import HeaderCart from "../../components/headerCart/headerCart"

// import ProductListing from "../../components/product/productsListing/productsListing"
import ProductsCategorySlider from "../../components/product/productsCategorySlider/productsCategorySlider"

const PageShop = () => (
  <Layout>
    <SEO title="Shop" />
    <br></br>
    <br></br>
    <div className={"ta-c"} style={{ minHeight: '100%' }}>
      {/* <ProductListing></ProductListing> */}
      <div className={"flex center marginPage"}>
        <div className={"col col-12"}>
          <ProductsCategorySlider />
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  </Layout>
)

export default PageShop
