import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import styles from "./headerClose.module.scss"

function HeaderClose() {
  return (
    <>
      <Link to={"/explore/"} className={styles.header_close}>
        <div className={styles.btn_close}>
          <div>
            <span></span>
            <span></span>
          </div>
        </div>
      </Link>
    </>
  )
}
HeaderClose.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderClose.defaultProps = {
  siteTitle: ``,
}

export default HeaderClose
